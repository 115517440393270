import { toError } from '@wix/pricing-plans-router-utils';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function withInteraction<P extends any[], T>(
  flowAPI: ControllerFlowAPI,
  name: string,
  action: (...args: P) => T | Promise<T>,
): (...args: P) => Promise<T> {
  const fn = async function (...args: P) {
    flowAPI.fedops.interactionStarted(name);
    try {
      const result = await action(...args);
      flowAPI.fedops.interactionEnded(name);
      return result;
    } catch (e) {
      flowAPI.errorMonitor.captureException(toError(e), {
        tags: {
          interaction: name,
        },
      });
      throw e;
    }
  };
  Object.defineProperty(fn, 'name', { value: 'monitored ' + action.name, writable: false });
  return fn;
}

export function withIgnoredErrors<P extends any[], T>(
  action: (...args: P) => T | Promise<T>,
): (...args: P) => Promise<T | undefined> {
  const fn = async function (...args: P) {
    try {
      return await action(...args);
    } catch {}
  };
  Object.defineProperty(fn, 'name', { value: 'silent ' + action.name, writable: false });
  return fn;
}
